<template>
  <v-list-item @click="$event => $emit('selectMarketplace', marketplace)">
    <v-badge
      color="green"
      bottom
      right
      dot
      offset-x="10"
      offset-y="10"
      class="mr-5"
    >
      <v-icon size="30">
        mdi-application
      </v-icon>
    </v-badge>
    <v-list-item-avatar />
    <v-list-item-content>
      <v-list-item-title>
        {{ marketplace.name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ $t('type') }}: {{ marketplace.type }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content class="text-right">
      <v-list-item-title>
        {{ marketplace }}%
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
    
    <script>
    export default {
        name: 'MarketplaceListItem',
        props: {
            marketplace: {
                required: true,
                type: Object,
                default: () => {}
            }
        }
    }
    </script>
    